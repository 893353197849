.App {
    background-image: url('../src/resources/background-desktop.jpeg'); /* Adjust path as needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.footer {
    position:fixed;
    left:0;
    bottom:0;
    right:0;
}

.Nav {
    position: fixed;
    margin: auto;
    width: 50%;
    left: 25%;
    padding: 10px;
    z-index: 150;
}

.NavMobile {
    margin: auto;
    width: 100%;
}